<template>
  <b-container>
    <b-card>
      <div class="d-inline-flex w-100 justify-content-start mb-1">
        <font-awesome-icon
          :icon="['fa', 'fa-exclamation-triangle']"
          class="text-warning font-large-2 mx-2 mt-50"
        />
        <div class="mt-50">
          <p class="mb-0">
            <b class="text-warning">User Warning</b>: This process can take a long time, depending on the size of the Model snapshot being imported.
          </p>
          <p>Large snapshots may take a considerable amount of time to finish.</p>
          <p>Please do not retry this action multiple times.</p>
        </div>
      </div>

      <b-form id="upload-form" @submit.prevent="importModelFile">
        <div class="d-inline-flex w-100 justify-content-center">
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              accept="tar.gz"
              placeholder="Select a model snapshot archive file (tar.gz)..."
              drop-placeholder="Drop file here..."
              class="ml-1 mr-2"
              @change="updateFiles"
            />
            <b-form-group class="w-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                :disabled="!file"
              >
                <span v-if="loading_status">
                  <b-spinner small type="grow" />
                  Importing...
                </span>
                <span v-else>
                  <font-awesome-icon
                    :icon="['fa', 'fa-file-import']"
                    class="text-white mr-50"
                  />
                  Import Model Snapshot
                </span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                class="ml-50"
              >
                Clear
              </b-button>
            </b-form-group>
        </div>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton,
  BCard,
  BContainer,
  BForm,
  BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'ImportModelSnapshot',
  components: {
    BContainer,
    BCard,
    BButton,
    BFormGroup,
    BForm,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: null,
      files: null,
      loading_status: false,
    }
  },
  methods: {
    updateFiles(evt) {
      this.files = evt.target.files
      // eslint-disable-next-line prefer-destructuring
      this.file = evt.target.files[0]
    },
    importModelFile(evt) {
      evt.preventDefault()
      this.loading_status = true
      if (this.file) {
        const formData = new FormData()
        formData.append('snapshot_file', this.file)

        // Initiate the API call without waiting for its completion
        coreService.post('/v1/snapshots/import', formData)
          .then(({ data }) => {
            // Store the task_id in the task list to watch progress
            this.$store.commit('workerTasks/ADD_TASK_TO_LIST', data.task_id)

            // Display a success message immediately
            this.$toast({
              component: ToastificationContent,
              props: {
                title: data.status,
                icon: 'LoaderIcon',
                variant: 'success',
              },
            })

            // Navigate away immediately
            this.$router.push({ name: 'model_browse' })
          })
          .catch(e => {
            // Handle errors
            const errorToast = {
              title: 'Import error',
              message: 'An unknown error occurred when trying to initiate the model snapshot import operation.',
            }

            if (e.response.data.message) {
              // Pass on the error message to the user if one is provided by the API
              errorToast.message = e.response.data.message
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorToast.title,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: errorToast.message,
              },
            })
          })
          .finally(() => {
            this.loading_status = false
          })
      }
    },
  },
}
</script>
